@use "@edgetier/styles/sass/spacing";
@use "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/variables";

.tooltip {
    $tooltip-background: colours.$flat-midnight-blue;
    $tooltip-color: colours.$flat-clouds;

    &__content {
        font-weight: bold;
        padding: spacing.$spacing-small;
        border-radius: 3px;
        z-index: 100000;
        background-color: $tooltip-background;
        color: $tooltip-color;
        animation: appear 250ms ease-out;
        max-width: 400px;
        line-height: variables.$line-height-base;

        .none {
            color: darken($tooltip-color, 20%);
        }
    }

    &__arrow {
        svg path {
            fill: $tooltip-background;
        }
    }
}
