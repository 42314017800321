@forward "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/colours" as *;

$background-colour: lighten($flat-clouds, 2%);
$divider-colour: darken($flat-clouds, 5%);
$placeholder-colour: $flat-concrete;

$color: $flat-midnight-blue;
$color-secondary: $flat-wet-asphalt;
$background: white;
$background-secondary: lighten($flat-clouds, 2%);
$background-tertiary: $flat-clouds;
$divider: darken($flat-clouds, 5%);
$label: $flat-asbestos;
$medium: $flat-concrete;
$pop-up-background: $flat-wet-asphalt;
$pop-up-color: white;
$scroll: $flat-concrete;
$modal-overlay-background: linear-gradient(to top, rgba($flat-clouds, 0.2), rgba($flat-midnight-blue, 0.5));
$loading-background: $flat-alizarin;
$primary: $flat-peter-river;
$primary-variant: $flat-belize-hole;
$positive: $flat-green-sea;
$positive-variant: darken($flat-green-sea, 5%);
$negative: $flat-alizarin;
$negative-variant: $flat-pomegranate;
$neutral: $flat-asbestos;
$neutral-variant: darken($flat-asbestos, 10%);
$grey: $flat-clouds;
$grey-variant: darken($flat-clouds, 10%);
$disabled: $flat-clouds;
$disabled-color: $flat-silver;
$alert-background: lighten($flat-alizarin, 37%);
$alert-color: $flat-alizarin;
$alert-border: $flat-alizarin;
